//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future

.p-overlaypanel-content {
 display: flex;
 flex-direction: column;
 align-items: center;
 width: 100px;
}

div,
ul,
.layout-topbar,
.p-inputtext,
.p-dropdown,
.p-button,
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-tbody > tr {
 transition: background-color 0.75s ease-in-out, border-color 0.75s ease-in-out,
  outline-color 0.75s ease-in-out;
}

.layout-sidebar {
 transition: transform $transitionDuration, left $transitionDuration,
  background-color 0.75s ease-in-out, border-color 0.75s ease-in-out,
  outline-color 0.75s ease-in-out;
}

.p-fluid .p-fileupload .p-button {
 width: 3rem;
}
