.flexContainer {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 5px;
}

.flexItem {
    flex-basis: 100%;
    border: 1px solid #ccc;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 12px;
    margin: 0px;
    margin-top: 18px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.flexItem.fullWidth {
    flex-basis: 100%;
    margin-bottom: 5px;
    margin-top: 14px;
}

.flexItem.fullWidth .imageContainer {
    padding-bottom: 0;
    height: auto;
}

.doubleHeight {
    height: 0;
    padding-bottom: 510px;
}

.doubleHeight .imageContainer {
    padding-bottom: 450px;
}

.imageContainer {
    width: 100%;
    height: 0;
    padding-bottom: 180px;
    overflow: hidden;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.cardImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.cardImage.fullWidth {
    position: relative;
    max-width: 100%;
    height: auto;
}

.textContainer {
    margin-top: 12px;
    text-align: center;
}

.name {
    font-size: 12px;
    color: #808080;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 11em;
}

.price {
    font-size: 10px;
    color: #5C3D80;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.circleBadge {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    background-color: #2196F3;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    font-weight: bold;
}