.layout-main-container {
 display: flex;
 flex-direction: column;
 min-height: 100vh;
 justify-content: space-between;
 padding: 7rem 2rem 2rem 4rem;
 transition: margin-left $transitionDuration;
}

.layout-auth-container {
 display: flex;
 min-height: 100vh;
 justify-content: center;
 align-items: center;
 padding: 3rem;
 transition: margin-left $transitionDuration;
}

.layout-main {
 flex: 1 1 auto;
}
