* {
 box-sizing: border-box;
}

html {
 height: 100%;
 font-size: $fontSize;
}

body {
 font-family: var(--font-family);
 color: var(--text-color);
 background-color: var(--surface-ground);
 margin: 0;
 padding: 0;
 min-height: 100%;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 transition: background-color 0.75s ease-in-out, border-color 0.75s ease-in-out,
  outline-color 0.75s ease-in-out;
}

a,
button {
 text-decoration: none;
 color: var(--primary-color);
}

.layout-theme-light {
 background-color: #edf1f5;
}

.layout-theme-dark {
 background-color: #040d19;
}
